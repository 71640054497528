import React, { memo } from "react";
import { RendererProps } from "./Grid";

import { CellStyle } from "./Theme";

import { Group, Rect, Text } from "react-konva";
import { KonvaEventObject } from "konva/types/Node";
import { isNull } from "./helpers";

export interface CellProps extends RendererProps {
  value?: string;
  textColor?: string;
  padding?: number;
  fontWeight?: string;
  fontStyle?: string;
  onClick?: (e: KonvaEventObject<MouseEvent>) => void;
  cellStyle?: CellStyle;
}

/**
 * Default cell component
 * @param props
 */
const CellComponent: React.FC<CellProps> = memo((props) => {
  const {
    x = 0,
    y = 0,
    width,
    height,
    value,
    cellStyle,
    fill = (cellStyle && cellStyle.background ? cellStyle.background  : "white"),
    strokeWidth = 1,
    stroke = "#d9d9d9",
    align = "left",
    verticalAlign = "middle",
    textColor = (cellStyle && cellStyle.textColor ? cellStyle.textColor  : "#333"),
    padding = 5,
    fontFamily = "Arial",
    fontSize = 12,
    children,
    wrap = "none",
    fontWeight = "normal",
    fontStyle = "normal",
    textDecoration,
    alpha = 1,
    strokeEnabled = false,
    globalCompositeOperation = "multiply",
    isOverlay,
    ...rest
  } = props;
  if (isOverlay) return null;
  const fillEnabled = !!fill;
  const textStyle = `${fontWeight} ${fontStyle}`;
  return (
    <Group {...rest}>
      <Rect
        x={x + 1}
        y={y + 1}
        height={height ? height - 2 : undefined}
        width={width ? width - 2 : undefined}
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
        shadowForStrokeEnabled={false}
        strokeScaleEnabled={false}
        hitStrokeWidth={0}
        alpha={alpha}
        fillEnabled={fillEnabled}
        strokeEnabled={strokeEnabled}
      />
      {isNull(value) ? null : (
        <Text
          x={x}
          y={y + 6}
          height={height ? height - 9 : undefined}
          width={width}
          text={value}
          fill={textColor}
          verticalAlign={verticalAlign}
          align={align}
          fontFamily={fontFamily}
          fontStyle={textStyle}
          textDecoration={textDecoration}
          padding={padding}
          wrap={wrap}
          fontSize={fontSize}
          hitStrokeWidth={0}
        />
      )}
      {children}
    </Group>
  );
});

/**
 * Default CellRenderer
 * @param props
 */
const CellRenderer = (props: RendererProps) => {
  return <CellComponent {...props} />;
};

export default CellRenderer;
export { CellRenderer, CellComponent };
