import React, {
  forwardRef,
  memo,
  useMemo,
  useRef,
  useState,
  useCallback,
  useEffect,
  useImperativeHandle,
} from "react";

import useMeasure from "react-use/esm/useMeasure";
import { ShapeConfig } from "konva/types/Shape";

import { RangeUtils } from "@sheetxl/models";
import { SSF } from "@sheetxl/models";
import { AdjustableColor } from "@sheetxl/models";

import { SheetTheme, CellStyle } from "./Theme";

import { CellComponent as DefaultCell } from "./Cell";
import Grid from "./Grid";
import GridHeader from "./GridHeader";
import {
  CellInterface,
  GridRef,
  Cell,
  CellContainer,
  MoveCellHandler,
  AreaProps,
  SelectionArea,
} from "./Grid";
import useEditable from "./hooks/useEditable";
import useSelection from "./hooks/useSelection";
import useHeaderSelection from "./hooks/useHeaderSelection";
import useSizer from "./hooks/useSizer";
import useCopyPaste from "./hooks/useCopyPaste";
import CellSelection from "./CellSelection";
import { extendCells } from "./helpers";
import { CellType } from "./types";

export interface RangeOverlay extends ShapeConfig {
  bounds?: AreaProps;

  //onMouseDown: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const defaultRangeOverlays: RangeOverlay[] = [];

const defaultCreateCellFromString = function (strValue: string): Cell {
  if (strValue === "" || strValue === null || strValue === undefined) {
    return undefined;
  }

  return {
    v: strValue,
    t: CellType.String,
  };
};

export interface UncontrolledSheetProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "onScroll">,
    MoveCellHandler {
  /**
   * No of columns in the grid
   */
  columnCount: number;
  /**
   * No of rows in the grid
   */
  rowCount: number;

  sheetTheme? : SheetTheme;
  rangeOverlays?: RangeOverlay[];

  getCell: (rowIndex: number, columnIndex: number) => Cell | undefined;

  setCells: (cells: CellContainer[]) => Cell;

  /**
   * Callback when a paste is executed
   */
  onMoveCell?: (from: CellInterface, to: CellInterface, cell?: Cell) => Cell;

  /**
   * Callback when the selection changes
   */
  onSelectionUpdate: (selections: SelectionArea[]) => void;

  edgeSelectAllStroke?: string;

  headerFill?: string;

  selectHeaderFill?: string;

  defaultHeaderFill?: string;

  handleLoaded?: () => void;

  createCellFromString?: (strValue: string) => Cell;
}

export type UncontrolledSheetRef = {
  rowCount: number;
  columnCount: number;
};

export type UncontrolledSheetRefAttribute = {
  ref?: React.Ref<UncontrolledSheetRef>;
};

const defaultGetTextValue = function (cell: Cell): string | undefined {
    let displayValue = cell?.v;
    if (cell && cell.z) {
        displayValue = SSF.format(cell.z, displayValue);
    }
    return displayValue as string;
};

const UncontrolledSheet: React.FC<
  UncontrolledSheetProps & UncontrolledSheetRefAttribute
> = memo(
  forwardRef<GridRef, UncontrolledSheetProps>((props, forwardedRef) => {
    const {
      getCell,
      setCells,
      sheetTheme,
      columnCount = 1000,
      rowCount = 1000,
      edgeSelectAllStroke = "#1a73e8",
      headerFill = "#eee",
      selectHeaderFill = "#b1b1b1", //#5e5e5e"; // excel grey "#dad8d6",
      defaultHeaderFill = "#eee",
      onMoveCell,
      onSelectionUpdate,
      rangeOverlays = defaultRangeOverlays,
      createCellFromString = defaultCreateCellFromString,
      handleLoaded,
      style: styleOveride = {},
      ...others
    } = props;

    if (!getCell) throw new Error("getCell must be provided");

    const {
        main : mainStyle = {} as CellStyle,
        header : headerStyle = {} as CellStyle
    } = sheetTheme || {};

    const gridRef = useRef<GridRef>(null);

    const getCellValue = useCallback(
      (cellRef: CellInterface) => {
        if (!getCell) return null;
        const cell = getCell(cellRef.rowIndex, cellRef.columnIndex);
        if (!cell) return null;
        return cell.v;
      },
      [getCell]
    );

    const frozenRows = 0;
    const frozenColumns = 0;
    const {
      activeCell,
      selections,
      setActiveCell,
      setSelections,
      isAllSelected,
      clearSelections,
      ...selectionProps
    } = useSelection({
      gridRef,
      initialActiveCell: {
        rowIndex: 0,
        columnIndex: 0,
      },
      getValue: getCellValue,
      rowCount,
      columnCount,
      onFill: (activeCell, fillSelection, selections) => {
        if (!fillSelection) return;
        const boundsExtend = fillSelection.bounds;

        let boundsInitial;
        if (selections.length > 0)
          boundsInitial = selections[selections.length - 1].bounds;
        else
          boundsInitial = {
            top: activeCell.rowIndex,
            left: activeCell.columnIndex,
            bottom: activeCell.rowIndex,
            right: activeCell.columnIndex,
          };
        const isVerticalFill =
          boundsExtend.left === boundsInitial.left &&
          boundsInitial.right === boundsExtend.right;

        const applyFill = function (
          start: number,
          range: number,
          isVertical: boolean,
          offset: number,
          initialCells: CellContainer[],
          inverted: boolean = false
        ) {
          if (inverted) initialCells = initialCells.reverse();
          let extendedCells = extendCells(initialCells, range);
          if (inverted) extendedCells = extendedCells.reverse();
          for (let i = 0; i < extendedCells.length; i++) {
            let fromCellRef: CellInterface = {
              rowIndex: extendedCells[i].rowIndex,
              columnIndex: extendedCells[i].columnIndex,
            };
            let toCellRef: CellInterface = {
              rowIndex: isVertical ? i + start : offset,
              columnIndex: isVertical ? offset : i + start,
            };
            if (onMoveCell) {
              extendedCells[i].cell = onMoveCell(
                fromCellRef,
                toCellRef,
                extendedCells[i].cell
              );
            }

            cells.push({
              rowIndex: toCellRef.rowIndex,
              columnIndex: toCellRef.columnIndex,
              cell: extendedCells[i].cell,
            });
          }
        };

        const cells: CellContainer[] = [];
        if (isVerticalFill) {
          // fill vertical
          let start = boundsInitial.bottom + 1;
          let range = boundsExtend.bottom - boundsInitial.top + 1;
          let inverted = false;
          if (boundsExtend.top < boundsInitial.top) {
            start = boundsExtend.top;
            range = boundsInitial.bottom - boundsExtend.top + 1;
            inverted = true;
          }

          for (let j = boundsExtend.left; j <= boundsExtend.right; j++) {
            let initialCells: CellContainer[] = [];
            for (let i = boundsInitial.top; i <= boundsInitial.bottom; i++) {
              const currentCell = getCell(i, j);
              initialCells.push({
                rowIndex: i,
                columnIndex: j,
                cell: currentCell,
              });
            }

            applyFill(start, range, isVerticalFill, j, initialCells, inverted);
          }
        } else {
          // fill hortizontal
          let start = boundsInitial.right + 1;
          let range = boundsExtend.right - boundsInitial.left + 1;
          let inverted = false;
          if (boundsExtend.left < boundsInitial.left) {
            start = boundsExtend.left;
            range = boundsInitial.right - boundsExtend.left + 1;
            inverted = true;
          }

          for (let j = boundsExtend.top; j <= boundsExtend.bottom; j++) {
            let initialCells = [];
            for (let i = boundsInitial.left; i <= boundsInitial.right; i++) {
              const currentCell = getCell(j, i);
              initialCells.push({
                rowIndex: j,
                columnIndex: i,
                cell: currentCell,
              });
            }

            applyFill(start, range, isVerticalFill, j, initialCells, inverted);
          }
        }
        if (cells.length > 0) setCells(cells);
      },
    });

    const { getTextMetrics, ...autoSizerProps } = useSizer({
      gridRef,
      getValue: getCellValue,
      getText: defaultGetTextValue,
      resizeStrategy: "lazy",
      rowCount,
      autoResize: false,
    });

    const getActualSelectionCoords = gridRef.current?.getActualSelectionCoords;

    const referenceOverlays = useMemo(() => {
      const overlayElements = rangeOverlays.map((overlay, index) => {
        const selectionOverlapProps = {
          ...overlay,
          gridRef: gridRef,
          key: 1001 + index,
          type: "range" as const,
          bounds: {
            left: overlay.bounds ? overlay.bounds.left : 0,
            top: overlay.bounds ? overlay.bounds.top : 0,
            bottom: overlay.bounds ? overlay.bounds.bottom : 0,
            right: overlay.bounds ? overlay.bounds.right : 0,
          },
        };
        return <CellSelection {...selectionOverlapProps}></CellSelection>;
      });
      return <>{overlayElements}</>;
    }, [rangeOverlays, getActualSelectionCoords]);

    const overlayComponent = useCallback(() => {
      return referenceOverlays;
    }, [gridRef.current, referenceOverlays]);

    const {
      editorComponent,
      isEditInProgress,
      onScroll: editorOnScroll,
      ...editableProps
    } = useEditable({
      gridRef,
      getValue: getCellValue,
      getCell: getCell,
      selections,
      activeCell,
      rowCount,
      columnCount,
      cellStyle: mainStyle,
      onDelete: (activeCell, selections) => {
        if (!setCells) return;
        if (selections.length) {
          let cells: CellContainer[] = [];
          for (let sel = 0; sel < selections.length; sel++) {
            for (
              let i = selections[sel].bounds.top;
              i <= selections[sel].bounds.bottom;
              i++
            ) {
              for (
                let j = selections[sel].bounds.left;
                j <= selections[sel].bounds.right;
                j++
              ) {
                const currentValue = getCellValue({
                  rowIndex: i,
                  columnIndex: j,
                });
                if (currentValue !== undefined)
                  cells.push({
                    rowIndex: i,
                    columnIndex: j,
                    cell: undefined,
                  });
              }
            }
          }
          if (cells.length > 0) setCells(cells);

          const selectionBounds = selections[0].bounds;

          gridRef.current?.resetAfterIndices(
            {
              rowIndex: selectionBounds.top,
              columnIndex: selectionBounds.left,
            },
            true
          );
        } else if (activeCell) {
          const currentValue = getCellValue(activeCell);
          if (currentValue !== undefined) {
            setCells([
              {
                rowIndex: activeCell.rowIndex,
                columnIndex: activeCell.columnIndex,
                cell: undefined,
              },
            ]);
          }

          gridRef.current?.resetAfterIndices(activeCell);
        }
      },
      canEdit: ({ rowIndex, columnIndex }) => {
        return !!setCells;
      },
      onSubmit: (
        value: React.ReactText,
        { rowIndex, columnIndex },
        nextActiveCell
      ) => {
        let cell = createCellFromString(value as string);
        let cells: CellContainer[] = [];
        cells.push({ rowIndex, columnIndex, cell });
        setCells(cells);
        gridRef.current?.resizeColumns([columnIndex]);

        /* Select the next cell */
        if (nextActiveCell) {
          setActiveCell(nextActiveCell);
        }
      },
    });
    const { copy, paste } = useCopyPaste({
      gridRef,
      selections,
      activeCell,
      getText: defaultGetTextValue,
      getCell: getCell,
      createCellFromString: createCellFromString,
      onMoveCell,
      onPaste: (
        cells,
        activeCell,
        currentSelection,
        cutSelection,
        newSelectionBounds
      ) => {
        if (activeCell === null) return;
        setCells(cells);
        // Note - This also forces a rerender
        gridRef.current?.resizeColumns([0]);

        /* Should select */
        if (!newSelectionBounds) {
          clearSelections();
          return;
        }
        setSelections([
          {
            bounds: newSelectionBounds,
          },
        ]);
      },
      onCut: (selection) => {
        const { bounds } = selection;
        const changes = {};
        // for (let i = bounds.top; i <= bounds.bottom; i++) {
        //   for (let j = bounds.left; j <= bounds.right; j++) {
        //     changes[[i, j]] = undefined;
        //   }
        // }
        // setData((prev) => ({ ...prev, ...changes }));
      },
    });

    const gridSelectProps = {
      fillHandleProps: selectionProps.fillHandleProps,
      fillSelection: selectionProps.fillSelection,
    };

    const gridAutoSizerProps = {
      onViewChange: autoSizerProps.onViewChange,
    };

    useEffect(() => {
      if (!onSelectionUpdate) return;
      if (selections && selections.length > 0) onSelectionUpdate(selections);
      // Note - If there is no selection we mimic using activeCell
      else
        onSelectionUpdate([
          {
            bounds: {
              top: activeCell.rowIndex,
              bottom: activeCell.rowIndex,
              left: activeCell.columnIndex,
              right: activeCell.columnIndex,
            },
          },
        ]);
    }, [selections, activeCell]);

    const refColumnHeader = useRef<GridRef>(null);
    const [columnWidthMap, setColumnWidthMap] = useState<
      Record<number, number>
    >({});
    const getColumnWidth = useCallback(
      (indx) => {
        if (indx in columnWidthMap) return columnWidthMap[indx];
        return 100;
      },
      [columnWidthMap]
    );
    const handleColumnResize = useCallback(
      (index, newValue) => {
        setColumnWidthMap((prev) => {
          return {
            ...prev,
            [index]: newValue,
          };
        });
        refColumnHeader.current?.resizeColumns([index]);
        gridRef.current?.resizeColumns([index]);
      },
      [setColumnWidthMap, refColumnHeader, gridRef]
    );

    const refRowHeader = useRef<GridRef>(null);
    const [rowHeightMap, setRowHeightMap] = useState<Record<number, number>>(
      {}
    );
    const getRowHeight = useCallback(
      (indx) => {
        if (indx in rowHeightMap) return rowHeightMap[indx];
        return 20;
      },
      [rowHeightMap]
    );
    const handleRowResize = useCallback(
      (index, newValue) => {
        setRowHeightMap((prev) => {
          return {
            ...prev,
            [index]: newValue,
          };
        });
        refRowHeader.current?.resizeRows([index]);
        gridRef.current?.resizeRows([index]);
      },
      [refColumnHeader, gridRef]
    );

    const [refContainerMain, { width, height }] = useMeasure<HTMLDivElement>();
    //     const refContainerMain = useRef<HTMLDivElement>();
    //     const width = 800;
    //     const height = 700;

    // Default column width
    // https://docs.microsoft.com/en-us/office/troubleshoot/excel/determine-column-widths
    // https://www.ablebits.com/office-addins-blog/2017/02/28/change-autofit-column-width-excel/
    const headerHeight = 20; // TODO - measure the font height
    const headerWidth = 60; // TODO - measure the font width

    const { ...selectionRowProps } = useHeaderSelection({
      isColumn: false,
      gridRef: refRowHeader,
      gridRefMain: gridRef,
      selectionMain: {
        activeCell,
        selections,
        setActiveCell,
        setSelections,
        isAllSelected,
        clearSelections,
        ...selectionProps,
      },
      alwaysScrollToActiveCell: false,
      rowCount,
      columnCount: 1,
    });

    const sheetHeaderRows = useCallback((index: number) => {
      return `${index + 1}`;
    }, []);

    const renderRowHeader = useCallback(
      (props) => {
        return (
          <GridHeader
            onResize={handleRowResize}
            frozenColumns={frozenColumns}
            frozenRows={frozenRows}
            direction="row"
            selections={selections}
            activeCell={activeCell}
            rowCount={rowCount}
            columnCount={columnCount}
            headerText={sheetHeaderRows}
            {...props}
          />
        );
      },
      [handleRowResize, frozenColumns, frozenRows, activeCell, selections]
    );

    const { ...selectionColumnProps } = useHeaderSelection({
      gridRef: refColumnHeader,
      gridRefMain: gridRef,
      selectionMain: {
        activeCell,
        selections,
        setActiveCell,
        setSelections,
        isAllSelected,
        clearSelections,
        ...selectionProps,
      },
      alwaysScrollToActiveCell: false,
      rowCount: 1,
      columnCount,
    });

    let gridHeaderSelectionSelections = useMemo(() => {
      if (!getActualSelectionCoords)
        return {
          rows: <></>,
          columns: <></>,
          highlightCorner: false,
        };

      const generateBorder = function (
        isColumn: boolean,
        key: string,
        selection: any
      ) {
        // TODO - make this a rect type
        if (isColumn)
          return (
            <div
              key={key}
              style={{
                position: "absolute",
                borderBottom: `2px solid ${edgeSelectAllStroke}`,
                pointerEvents: "none",
                top: "0px",
                left: `${selection.x - 1}px`,
                width: `${selection.width + 2}px`,
                height: `${headerHeight}px`,
              }}
            ></div>
          );
        else
          return (
            <div
              key={key}
              style={{
                position: "absolute",
                borderRight: `2px solid ${edgeSelectAllStroke}`,
                pointerEvents: "none",
                top: `${selection.y - 1}px`,
                left: "0px",
                width: `${headerWidth}px`,
                height: `${selection.height + 2}px`,
              }}
            ></div>
          );
      };

      const getSelectionCoords = (
        left: number,
        top: number,
        right: number,
        bottom: number
      ) => {
        const selectionBounds = { x: 0, y: 0, width: 0, height: 0 };
        const actualBottom = Math.min(gridRef.current.rowCount, bottom);
        const actualRight = Math.min(gridRef.current.columnCount, right);

        selectionBounds.y = gridRef.current.getRowOffset(top);
        selectionBounds.height =
          gridRef.current.getRowOffset(actualBottom) -
          selectionBounds.y +
          gridRef.current.getRowHeight(actualBottom);

        selectionBounds.x = gridRef.current.getColumnOffset(left);

        selectionBounds.width =
          gridRef.current.getColumnOffset(actualRight) -
          selectionBounds.x +
          gridRef.current.getColumnWidth(actualRight);

        return {
          x: selectionBounds.x,
          y: selectionBounds.y,
          width: selectionBounds.width,
          height: selectionBounds.height,
        };
      };

      const gridColumnSelections = [];
      const gridRowSelections = [];
      let highlightCorner = false;

      const anchorSelection = getSelectionCoords(
        activeCell.columnIndex,
        activeCell.rowIndex,
        activeCell.columnIndex,
        activeCell.rowIndex
      );
      gridColumnSelections.push(
        generateBorder(true, "anchorColumn", anchorSelection)
      );
      gridRowSelections.push(
        generateBorder(false, "anchorRow", anchorSelection)
      );
      highlightCorner =
        activeCell.columnIndex === 0 || activeCell.rowIndex === 0;

      for (let i = 0; selections && i < selections.length; i++) {
        const currentSelection = getSelectionCoords(
          selections[i].bounds.left,
          selections[i].bounds.top,
          selections[i].bounds.right,
          selections[i].bounds.bottom
        );
        gridColumnSelections.push(
          generateBorder(true, "selectionColumn" + i, currentSelection)
        );
        gridRowSelections.push(
          generateBorder(false, "selectionRow" + i, currentSelection)
        );
        highlightCorner =
          highlightCorner ||
          selections[i].bounds.left === 0 ||
          selections[i].bounds.top === 0;
      }
      return {
        rows: <>{gridRowSelections}</>,
        columns: <>{gridColumnSelections}</>,
        highlightCorner,
      };
    }, [getActualSelectionCoords, selections, activeCell]);

    const overlayColumns = useCallback(
      ({ scrollLeft, scrollTop }) => {
        return gridHeaderSelectionSelections.columns;
      },
      [gridHeaderSelectionSelections]
    );

    const overlayRows = useCallback(() => {
      return gridHeaderSelectionSelections.rows;
    }, [gridHeaderSelectionSelections]);

    const gridRowHeader = useMemo(() => {
      return (
        <Grid
          columnCount={1}
          rowCount={rowCount}
          frozenRows={frozenRows}
          ref={refRowHeader}
          width={headerWidth}
          height={height}
          columnWidth={(index) => {
            return headerWidth;
          }}
          onMouseDown={(e) => {
            selectionRowProps.onMouseDown(e);
          }}
          //columnWidth={40} // TODO - use autosizer
          rowHeight={getRowHeight}
          showScrollbar={false}
          itemRenderer={renderRowHeader}
          childrenOverlay={overlayRows}
        />
      );
    }, [
      rowCount,
      headerWidth,
      frozenColumns,
      frozenRows,
      height,
      refRowHeader,
      getRowHeight,
      handleRowResize,
      renderRowHeader,
    ]);

    const sheetHeaderColumn = useCallback((index: number) => {
      return `${RangeUtils.encode_col(index)}`;
    }, []);

    const gridColumnHeader = useMemo(() => {
      return (
        <Grid
          columnCount={columnCount}
          height={headerHeight}
          rowCount={1}
          frozenColumns={frozenColumns}
          ref={refColumnHeader}
          width={width}
          columnWidth={getColumnWidth}
          rowHeight={(index) => {
            return headerHeight;
          }}
          showScrollbar={false}
          onMouseDown={(e) => {
            selectionColumnProps.onMouseDown(e);
          }}
          itemRenderer={(props) => (
            <GridHeader
              onResize={handleColumnResize}
              frozenColumns={frozenColumns}
              frozenRows={frozenRows}
              direction="column"
              selections={selections}
              activeCell={activeCell}
              rowCount={rowCount}
              columnCount={columnCount}
              headerText={sheetHeaderColumn}
              {...props}
            />
          )}
          childrenOverlay={overlayColumns}
        />
      );
    }, [
      columnCount,
      headerHeight,
      frozenColumns,
      frozenRows,
      width,
      refColumnHeader,
      getColumnWidth,
      handleColumnResize,
      activeCell,
      selections,
    ]);

    const gridValueRenderer = useCallback(
      (props) => {
        //console.log(getTextMetrics('test'), autoSizerProps);
        const cell = getCell(props.rowIndex, props.columnIndex);

        let displayValue = defaultGetTextValue(cell);

        let align = "left";
        if (cell?.s?.align)
            align = cell.s.align;
        else if (cell?.t === CellType.Number)
            align = "right";
        else if (cell?.t === CellType.Boolean)
            align = "center";

        let renderStyle = mainStyle;

        // TODO - cacche these and rationalize with chart (move SSF into wrapped class)
        const regExpColorFormat = /\[(.*?)\]/;
        if (cell.z) {
          let matches = regExpColorFormat.exec(cell.z);
          if (matches) {
            try {
              const colorStr = matches[1].charAt(0).toUpperCase() + matches[1].slice(1)
              let aj = new AdjustableColor(colorStr);
              renderStyle = {...renderStyle};
              renderStyle.textColor = aj.toRGBAColor().toString();
            } catch (error) {
              console.log('invalid color code', error);
            }
          }
        }
        return (
          <DefaultCell
            value={displayValue}
            align={align}
            cellStyle={renderStyle}
            {...props}
          />
        );
      },
      [getCell, mainStyle]
    );

    let selectAllBorder = isAllSelected()
      ? edgeSelectAllStroke
      : selectHeaderFill;

    /* Expose some methods in ref */
    useImperativeHandle(forwardedRef, () => {
      return gridRef.current;
    });

    useEffect(() => {
      if (typeof handleLoaded === "function") {
        handleLoaded();
      }
      return () => {
        // fire unloaded
      };
    }, [handleLoaded]);

    const styleEffective = useMemo(() => {
      return Object.assign(
        {
          position: "relative",
        },
        styleOveride
      );
    }, [styleOveride]);

    return (
      <div style={styleEffective} {...others}>
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flex: "1 1 100%",
              overflow: "hidden",
            }}
          >
            <div
              tabIndex={-1}
              style={{
                //border: 'red solid 2px',
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  padding: "2 2",
                  maxHeight: `${headerHeight}px`,
                  background: `${defaultHeaderFill}`,
                  borderRight: "solid 1px #dfdfdf",
                  borderBottom: "solid 1px #dfdfdf",
                }}
                onMouseDown={(...args) => {
                  selectionProps.selectAll();
                  setActiveCell({ columnIndex: 0, rowIndex: 0 });
                  // Note - this isn't working
                  gridRef.current?.focus();
                }}
              >
                <div
                  style={{
                    borderTop: `${headerHeight - 5}px solid transparent`,
                    borderRight: `${
                      headerHeight - 5
                    }px solid ${selectAllBorder}`,
                    height: `${headerHeight - 6}px`,
                    margin: "2px 2px",
                    boxSizing: "border-box",
                    cursor: "cell",
                  }}
                ></div>
                <div
                  style={{
                    display: `${
                      gridHeaderSelectionSelections.highlightCorner
                        ? "block"
                        : "none"
                    }`,
                    position: "absolute",
                    left: `${
                      headerWidth -
                      (gridHeaderSelectionSelections.highlightCorner ? 2 : 1)
                    }px`,
                    top: `${
                      headerHeight -
                      (gridHeaderSelectionSelections.highlightCorner ? 2 : 1)
                    }px`,
                    width: `${
                      gridHeaderSelectionSelections.highlightCorner ? 2 : 1
                    }px`,
                    height: `${
                      gridHeaderSelectionSelections.highlightCorner ? 2 : 1
                    }px`,
                    background: `${
                      gridHeaderSelectionSelections.highlightCorner
                        ? edgeSelectAllStroke
                        : selectHeaderFill
                    }`,
                  }}
                ></div>
              </div>
              <div
                tabIndex={-1}
                style={{
                  position: "relative",
                  flex: "1 1 100%",
                  width: `${headerWidth}px`,
                  minWidth: `${headerWidth}px`,
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                  }}
                >
                  {gridRowHeader}
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: "1 1 100%",
              }}
            >
              <div
                style={{
                  position: "relative",
                  height: `${headerHeight}px`,
                  minHeight: `${headerHeight}px`,
                }}
              >
                <div
                  tabIndex={-1}
                  style={{
                    //border: 'red solid 2px',
                    position: "absolute",
                  }}
                >
                  {gridColumnHeader}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: "1 1 100%",
                  position: "relative",
                  overflow: "hidden",
                }}
                ref={refContainerMain}
              >
                <div
                  style={{
                    position: "absolute",
                  }}
                >
                  <Grid
                    frozenColumns={frozenColumns}
                    frozenRows={frozenRows}
                    showFrozenShadow={true}
                    showGridLines={true}
                    gridLineColor={mainStyle.stroke}
                    fillhandleBorderColor={mainStyle.background}
                    width={width}
                    height={height}
                    columnCount={columnCount}
                    rowCount={rowCount}
                    ref={gridRef}
                    activeCell={activeCell}
                    selections={selections}
                    columnWidth={getColumnWidth}
                    rowHeight={getRowHeight}
                    showFillHandle={setCells && !isEditInProgress}
                    itemRenderer={gridValueRenderer}
                    //enableSelectionDrag={true}
                    {...gridSelectProps}
                    {...gridAutoSizerProps}
                    onKeyDown={(...args) => {
                      selectionProps.onKeyDown(...args);
                      editableProps.onKeyDown(...args);
                    }}
                    isHiddenCell={function (
                      rowIndex: number,
                      columnIndex: number
                    ) {
                      if (getCell(rowIndex, columnIndex)) {
                        return false;
                      }
                      return true;
                    }}
                    onMouseDown={(...args) => {
                      selectionProps.onMouseDown(...args);
                      editableProps.onMouseDown(...args);
                    }}
                    onDoubleClick={(...args) => {
                      editableProps.onDoubleClick(...args);
                    }}
                    //onScroll
                    onImmediateScroll={({ scrollTop, scrollLeft }) => {
                      if (editorOnScroll)
                        editorOnScroll({ scrollTop, scrollLeft });
                      refColumnHeader.current?.scrollTo({
                        scrollTop: 0,
                        scrollLeft,
                      });
                      refRowHeader.current?.scrollTo({
                        scrollTop,
                        scrollLeft: 0,
                      });
                    }}
                    childrenOverlay={overlayComponent}
                  ></Grid>
                  {editorComponent}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  })
);

export default UncontrolledSheet;
