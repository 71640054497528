// @ts-nocheck
import React, {
  useCallback,
  useImperativeHandle,
  useRef,
  memo,
  forwardRef,
} from "react";

import { Cell, CellContainer } from "./Grid";
import { UncontrolledSheetProps } from "./UncontrolledSheet";
import UncontrolledSheet from "./UncontrolledSheet";

import { SheetTheme } from "./Theme";


export type SheetRef = {
  sheetRef: SheetRef;
  getCell: (rowIndex: number, columnIndex: number) => Cell | undefined;
  setCells: (cells: CellContainer[]) => Cell;
};

export type SheetRefAttribute = {
  ref?: React.Ref<SheetRef>;
};

export interface SheetProps extends UncontrolledSheetProps {
  sheetSource?: any; // This should not be any but not sure how to type
  createCellFromString?: (strValue: string) => Cell;
  sheetTheme? : SheetTheme;
}

const Sheet: React.FC<SheetProps & SheetRefAttribute> = memo(
  forwardRef<SheetRef, SheetProps>((props, forwardedRef) => {
    const { 
      sheetSource,
      createCellFromString,
      ...others } = props;

    //const [data, setData] = useState(initalCells);
    const getCell = useCallback(
      (row, column) => {
        if (!sheetSource) return undefined;
        return sheetSource.getCellAt(column, row);
      },
      [sheetSource]
    );

    let setCells = undefined;
    if (sheetSource)
      setCells = useCallback(
        (changes) => {
          sheetSource.setCells(changes);
        },
        [sheetSource]
      );

    const sheetRef = useRef<SheetRef>(null);

    /* Expose some methods in ref */
    useImperativeHandle(forwardedRef, () => {
      return {
        getCell,
        setCells,
        sheetRef: sheetRef?.current, // TODO - set ref on sheet to get gridref gridRef.current
      };
    });

    const handleKeyDown = useCallback(
      (e: React.KeyboardEvent) => {
        if (!sheetRef?.current) return;
        const isShiftKey = e.nativeEvent.shiftKey;
        const isAltKey = e.nativeEvent.altKey;
        const isMetaKey = e.nativeEvent.ctrlKey || e.nativeEvent.metaKey;
        if (isMetaKey && e.nativeEvent.which === 83) { // 's'
            if (sheetSource.save)
                sheetSource.save();
            e.preventDefault();
            return;
        } else if (isMetaKey && e.nativeEvent.which === 79) { // 'o'
          if (sheetSource.open)
              sheetSource.open();
          e.preventDefault();
          return;
        }
    }, [ sheetRef ]);

    return (
      <UncontrolledSheet
        ref={sheetRef}
        createCellFromString={createCellFromString}
        getCell={getCell}
        setCells={setCells}
        onKeyDown={handleKeyDown}
        {...others}
      />
    );
  })
);

export default Sheet;
