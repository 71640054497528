import React, { useCallback, useRef } from "react";
import { SelectionArea, CellInterface, GridRef } from "./../Grid";
import { isEqualCells } from "./../helpers";
import { MouseButtonCodes, SelectionPolicy } from "./../types";

import { SelectionResults } from "./useSelection";

/**
 * this hook is just manages the header selections. 
 *
    TODO - When dragging a selection that already exist it should drag for reorder. (Not supported yet)
    TODO - read hidden rows/columns from mainGrid
    TODO - Context menus
*/
export interface UseHeaderSelectionOptions {
  /**
   * Access grid functions
   */
  gridRefMain: React.MutableRefObject<GridRef | null>;

  selectionMain: SelectionResults;

  isColumn?: boolean;
  /**
   * Access grid functions
   */
  gridRef: React.MutableRefObject<GridRef | null>;

  /**
   * No of columns in the grid
   */
  columnCount?: number;
  /**
   * No of rows in the grid
   */
  rowCount?: number;
  /**
   * Always scroll to active cell
   */
  alwaysScrollToActiveCell?: boolean;

  /**
   * Mousedown
   */
  mouseDownInterceptor?: (
    e: React.MouseEvent<HTMLDivElement>,
    coords: CellInterface,
    start: React.MutableRefObject<CellInterface | null>,
    end: React.MutableRefObject<CellInterface | null>
  ) => boolean | undefined;
  mouseMoveInterceptor?: (
    e: globalThis.MouseEvent,
    coords: CellInterface,
    start: React.MutableRefObject<CellInterface | null>,
    end: React.MutableRefObject<CellInterface | null>
  ) => boolean | undefined;
  canSelectionSpanMergedCells?: (
    start: CellInterface,
    end: CellInterface
  ) => boolean;
  /**
   * Selection policy
   */
  selectionPolicy?: SelectionPolicy;
  /**
   * New selection mode
   */
  newSelectionMode?: NewHeaderSelectionMode;
}

export type NewHeaderSelectionMode = "clear" | "modify" | "append";

export interface HeaderSelectionResults {
  /**
   * Array of all selection bounds
   */
  //selections: SelectionArea[];
  /**
   * Handler for mousedown, use to set activeCell
   */
  onMouseDown: (e: React.MouseEvent<HTMLDivElement>) => void;
  /**
   * On Selection mousedown
   */
  onSelectionMouseDown?: (
    e: React.MouseEvent<HTMLDivElement>,
    cell: CellInterface | undefined,
    selection: SelectionArea | undefined,
    index: number | undefined,
    shouldClamp?: boolean
  ) => void;

  /**
   * Currently dragged selection
   */
  draggedSelection?: SelectionArea;
  /**
   * The selection that user selected before beginning
   * the drag
   */
  initialDraggedSelection?: SelectionArea;
}

/**
 * Hook to enable selection in datagrid
 */
const useHeaderSelection = ({
  gridRef,
  gridRefMain,
  selectionMain,
  isColumn = true,
  columnCount = 0,
  rowCount = 0,
  selectionPolicy = "multiple",
  newSelectionMode = "clear",
  alwaysScrollToActiveCell = true,
  mouseDownInterceptor,
  mouseMoveInterceptor,
}: UseHeaderSelectionOptions): HeaderSelectionResults => {
  const selectionStart = useRef<CellInterface | null>(null);
  const selectionEnd = useRef<CellInterface | null>(null);
  const isSelecting = useRef<boolean>(false);
  const firstActiveCell = useRef<CellInterface | null>(null);

  /* Drag drop selection */
  // const initialDraggedSelection = useRef<SelectionArea>();
  // const initialDraggedCell = useRef<CellInterface>();
  // const draggedSelection = useRef<SelectionArea>();
  // const draggedSelectionIndex = useRef<number>();

  /* Check if cell is out of bounds */
  const getFullEnd = useCallback(
    (cell: CellInterface) => {
      if (isColumn)
        return {
          rowIndex: gridRefMain.current ? gridRefMain.current.rowCount - 1 : 0,
          columnIndex: cell.columnIndex,
        };
      else {
        return {
          rowIndex: cell.rowIndex,
          columnIndex: gridRefMain.current
            ? gridRefMain.current.columnCount - 1
            : 0,
        };
      }
    },
    [isColumn]
  );

  /* New selection */
  const newSelection = (start: CellInterface, end: CellInterface = start) => {
    selectionStart.current = start;
    selectionEnd.current = getFullEnd(end);
    selectionMain.newSelection(selectionStart.current, selectionEnd.current);
  };

  /* Modify current selection */
  const modifySelection = (coords: CellInterface, setInProgress?: boolean) => {
    selectionEnd.current = getFullEnd(coords);
    selectionMain.modifySelection(selectionEnd.current, setInProgress);
    return;
  };

  /* Adds a new selection, CMD key */
  const appendSelection = (
    start: CellInterface,
    end: CellInterface = start
  ) => {
    selectionStart.current = start;
    selectionEnd.current = getFullEnd(end);
    selectionMain.appendSelection(selectionStart.current, selectionEnd.current);
  };

  /**
   * Triggers a new selection start
   */
  const handleMouseDown = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      /* Exit early if grid is not initialized */
      if (!gridRef || !gridRef.current) return;
      if (e.nativeEvent.defaultPrevented) return;
      const coords = gridRef.current.getCellCoordsFromOffset(
        e.nativeEvent.clientX,
        e.nativeEvent.clientY
      );
      if (!coords) return;
      /* Check if its context menu click */
      const isContextMenuClick = e.nativeEvent.which === MouseButtonCodes.right;
      if (isContextMenuClick) {
        //         const cellIndex = cellIndexInSelection(coords, selectionMain.selections);
        //         if (cellIndex !== -1) return;
      }
      const isShiftKey = e.nativeEvent.shiftKey;
      const isMetaKey = e.nativeEvent.ctrlKey || e.nativeEvent.metaKey;
      const allowMultiple = isMetaKey;

      if (!isContextMenuClick && selectionPolicy !== "single") {
        document.addEventListener("mouseup", handleMouseUp, { passive: true });
        document.addEventListener("mousemove", handleMouseMove, {
          passive: true,
        });
      }

      /* Activate selection mode */
      isSelecting.current = true;

      if (
        mouseDownInterceptor?.(e, coords, selectionStart, selectionEnd) ===
        false
      ) {
        return;
      }

      /* Shift key */
      if (isShiftKey) {
        modifySelection(coords);
        return;
      }

      /* Command  or Control key */
      if (allowMultiple) {
        /**
         * TODO
         * 1. Ability to remove selection
         * 2. Ability to remove from selection area
         * 3. Ability to switch activeCell if its part of removed selection
         */
        appendSelection(coords);
        return;
      }

      /**
       * Scroll to the selected cell
       */
      if (alwaysScrollToActiveCell) {
        gridRefMain.current?.scrollToItem(coords);
      }

      newSelection(coords);
      modifySelection(coords, false);
    },
    [
      selectionPolicy,
      alwaysScrollToActiveCell,
      rowCount,
      columnCount,
      newSelectionMode,
      //       selectionMain,
      //       gridRefMain
    ]
  );

  /**
   * Mousemove handler
   */
  const handleMouseMove = useCallback(
    (e: globalThis.MouseEvent) => {
      /* Exit if user is not in selection mode */
      if (!isSelecting.current || !gridRef?.current) return;

      const coords = gridRef.current.getCellCoordsFromOffset(
        e.clientX,
        e.clientY,
        false
      );

      if (!coords) return;

      if (
        mouseMoveInterceptor?.(e, coords, selectionStart, selectionEnd) ===
        false
      ) {
        return;
      }

      if (isEqualCells(firstActiveCell.current, coords)) {
        return selectionMain.clearSelections();
      }

      modifySelection(coords, false);

      gridRefMain.current?.scrollToItem(coords);
    },
    [
      //     selectionMain,
      //     gridRefMain
    ]
  );

  /**
   * Mouse up handler
   */
  const handleMouseUp = useCallback(() => {
    /* Reset selection mode */
    isSelecting.current = false;
    gridRefMain.current?.focus();

    /* Remove listener */
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  }, [
    //     selectionMain,
    gridRefMain,
  ]);

  return {
    // draggedSelection: draggedSelection.current,
    // initialDraggedSelection: initialDraggedSelection.current,
    onMouseDown: handleMouseDown,
  };
};

export default useHeaderSelection;
