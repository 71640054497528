import React from "react";
import { memo } from "react";
import Konva from "konva";

import { Rect, Line, Text, Group } from "react-konva";
import { ShapeConfig } from "konva/types/Shape";

import { GridProps } from "./Grid";
import { CellProps } from "./Cell";
import { SelectionArea, CellInterface } from "./Grid";

export interface GridHeaderProps extends Omit<GridProps, "key"> {
  onResize?: (columnIndex: number, newWidth: number) => void;
}

export interface GridHeaderCellProps extends CellProps {
  directionStyle?: "horizontal" | "vertical" | "row" | "column";
  headerText?: (index: number) => string;
  headerFill?: string;
  headerStroke?: string;
  headerStrokeWidth?: number;
  dividerStroke?: string;
  activeCell?: CellInterface | null;
  selections?: SelectionArea[];
  rowCount: number;
  columnCount: number;

  onResize?: (columnIndex: number, newWidth: number) => void;
}

export interface DraggableRectProps extends ShapeConfig {
  isColumn: boolean;
}

const dragHandleWidth = 14;
const dragHandleHeight = 8;
const dividerWidth = 1;
const edgeWidth = 1;
const minHeaderSize = 5; // TODO - We should allow 0 length here. This will then make this value 'hidden'

const edgeStroke = "#b1b1b1"; //#5e5e5e"; // excel grey
const edgeSelectStroke = "#b1b1b1"; // "#1a73e8"; // google blue

const defaultHeaderFill = "#eee";
const defaultDividerStroke = "#dfdfdf"; // "grey";
//const selectHeaderFill = "#dad8d6";

const selectHeaderFill = "rgb(14, 101, 235, 0.2)"; //edgeSelectStroke;

const selectText = "rgb(14, 101, 235, 1.0)"; //google blue;
const selectAllHeaderFill = "rgb(14, 101, 235, 0.4)"; //edgeSelectStroke;

const DraggableRect: React.FC<DraggableRectProps> = (
  props: Konva.RectConfig
) => {
  const { isColumn = true, ...rest } = { ...props };
  return (
    <Rect
      draggable
      //fill="rgba(0,0,255, 0.5)"
      onMouseDown={(event: any) => {
        event.evt.preventDefault();
        event.evt.stopPropagation();
        window.addEventListener(
          "mouseup",
          function () {
            document.body.style.cursor = "unset";
          }.bind(this),
          { once: true, passive: true }
        );
      }}
      //onMouseEnter={() => (document.body.style.cursor = "ew-resize")}
      onMouseMove={() =>
        (document.body.style.cursor = isColumn ? "ew-resize" : "ns-resize")
      }
      onMouseLeave={() => (document.body.style.cursor = "unset")}
      onDblClick={(evt: Konva.KonvaEventObject<MouseEvent>) => {
        console.log("doubleClick", evt); // TODO - Auto size
      }}
      {...props}
    />
  );
};

const hasSelection = function (
  activeCell: CellInterface | null,
  selections: SelectionArea[],
  isColumn: boolean,
  columnIndex: number,
  rowIndex: number,
  columnCount: number,
  rowCount: number
) {
  let isSelected = false;
  let isAllSelected = false;

  for (let i = 0; !isSelected && i < selections.length; i++) {
    let selection = selections[i];
    if (isColumn) {
      if (
        selection.bounds.left <= columnIndex &&
        selection.bounds.right >= columnIndex
      )
        isSelected = true;
      if (isSelected && columnCount) {
        isAllSelected =
          selection.bounds.top === 0 &&
          selection.bounds.bottom === columnCount - 1;
      }
    } else {
      if (
        selection.bounds.top <= rowIndex &&
        selection.bounds.bottom >= rowIndex
      )
        isSelected = true;
      if (isSelected && rowCount) {
        isAllSelected =
          selection.bounds.left === 0 &&
          selection.bounds.right === rowCount - 1;
      }
    }
  }

  if (!isSelected && activeCell) {
    if (
      (isColumn && activeCell.columnIndex === columnIndex) ||
      (!isColumn && activeCell.rowIndex === rowIndex)
    )
      isSelected = true;
  }

  return {
    isSelected: isSelected,
    isAllSelected: isAllSelected,
  };
};

const GridHeader: React.FC<GridHeaderCellProps> = memo(
  (props: GridHeaderCellProps) => {
    const isColumn =
      props.direction !== "row" && props.direction !== "vertical";
    //const ref = useRef<any>(); // Note - Why is Konva.Rect not working?

    const {
      direction = "column",
      headerText = (index: number) => `${isColumn ? "Col" : ""} ${index}`,
      columnIndex,
      rowIndex,
      x = 0,
      y = 0,
      width = 0,
      height = 0,
      frozenColumns = 0,
      frozenRows = 0,
      headerFill = defaultHeaderFill,
      headerStroke,
      headerStrokeWidth = 1,
      dividerStroke = defaultDividerStroke,
      activeCell = null,
      selections = [],
      selectionsHeader = [],
      rowCount,
      columnCount,
      isMergedCell = false,
      onResize,
      ...rest
    } = props;

    const index = isColumn ? columnIndex : rowIndex;

    const effectiveHeight = isColumn ? height - edgeWidth : height;
    const effectiveWidth = isColumn ? width : width - edgeWidth;

    const text = headerText(index);
    const dragHandleLength = isColumn ? dragHandleWidth : dragHandleHeight;
    let shiftedOffset = dragHandleLength / 2;
    let shiftedLength = isColumn ? width : effectiveHeight;

    const { isSelected = false, isAllSelected = false } = hasSelection(
      activeCell,
      selections,
      isColumn,
      columnIndex,
      rowIndex,
      columnCount,
      rowCount
    );

    const dividerPoints = [
      isColumn
        ? x + shiftedOffset - dragHandleLength / 2 + dividerWidth / 2
        : 0,
      isColumn
        ? y
        : y + shiftedOffset - dragHandleLength / 2 + dividerWidth / 2,
      isColumn
        ? x + shiftedOffset - dragHandleLength / 2 + dividerWidth / 2
        : width,
      isColumn
        ? y + height
        : y + shiftedOffset - dragHandleLength / 2 + dividerWidth / 2,
    ];
    // TODO - Excel has a 'nice touch' where it uses the wider selections
    //        border. We can't do this because the next renderer will
    //        override. To do this we need to paint the the selection outside of the header
    //        using a selection painter
    const edgePoints = [
      isColumn ? x : x + width - edgeWidth / 2,
      isColumn ? y + height - edgeWidth / 2 : y,
      isColumn ? x + width : x + width - edgeWidth / 2,
      isColumn ? y + height - edgeWidth / 2 : y + height,
    ];

    const draggableRect = onResize ? (
      <DraggableRect
        isColumn={isColumn}
        x={isColumn ? x + shiftedOffset + shiftedLength - dragHandleLength : x}
        y={isColumn ? y : y + shiftedOffset + shiftedLength - dragHandleLength}
        width={isColumn ? dragHandleLength : effectiveWidth}
        height={isColumn ? effectiveHeight : dragHandleLength}
        dragBoundFunc={function (pos) {
          //const node = ref.current;
          let retValue;
          if (isColumn) {
            retValue = { x: pos.x, y: 0 };
          } else {
            retValue = { x: 0, y: pos.y };
          }
          //console.log('drag1', node.getClientRect().x, node.getClientRect().y, pos, retValue);
          return retValue;
        }}
        onDragMove={(event: any) => {
          const node = event.target;
          event.evt.preventDefault();
          event.evt.stopPropagation();
          const index = isColumn ? columnIndex : rowIndex;
          const newLength =
            (isColumn ? node.x() - x : node.y() - y) + dragHandleLength / 2;
          //console.log('onResize('+index + ', ' + newLength + ')');
          onResize(index, Math.max(minHeaderSize, newLength));
        }}
      />
    ) : (
      <></>
    );
    // The group is
    // background
    // text
    // drag rect
    // drag line
    // divider line
    // edge line (for now)
    return (
      <Group {...rest}>
        <Rect
          //ref={ref}
          x={x}
          y={y}
          height={isColumn ? effectiveHeight : shiftedLength}
          width={isColumn ? shiftedLength : effectiveWidth}
          fill={
            isAllSelected
              ? selectAllHeaderFill
              : isSelected
              ? selectHeaderFill
              : headerFill
          }
          hitFunc={(context) => {
            return null; // This line has no hit function as we use the dragbounds instead
          }}
        />
        <Group
          clip={{
            x: x,
            y: y,
            width: effectiveWidth,
            height: effectiveHeight,
          }}
        >
          <Text
            x={x}
            y={y - 1}
            height={effectiveHeight}
            width={effectiveWidth}
            text={text}
            //fill="red"
            fill={isSelected ? selectText : "inherit"}
            fontStyle={isSelected ? "bold" : "normal"}
            verticalAlign="bottom"
            align="center"
            hitFunc={(context) => {
              return null; // This line has no hit function as we use the dragbounds instead
            }}
          />
        </Group>
        {draggableRect}
        {index ? (
          <Line
            points={dividerPoints}
            stroke={dividerStroke}
            shadowColor={edgeStroke}
            //shadowBlur={0.5}
            shadowOffset={isColumn ? { x: 0.75, y: 0 } : { x: 0, y: 0.75 }}
            shadowOpacity={0.0} // turned off shadow for now.
            strokeWidth={dividerWidth}
            hitFunc={(context) => {
              return null; // This line has no hit function as we use the dragbounds instead
            }}
          />
        ) : (
          <></>
        )}
        <Line
          points={edgePoints}
          stroke={
            isAllSelected
              ? edgeSelectStroke
              : isSelected
              ? edgeSelectStroke
              : edgeStroke
          }
          // shadowColor={dividerStroke}
          // shadowBlur={0.5}
          // shadowOffset={!isColumn ? { x: 0.75, y: 0 } : { x: 0, y: 0.75 }}
          // shadowOpacity={0.6}
          strokeWidth={edgeWidth}
          hitFunc={(context) => {
            return null; // This line has no hit function as we use the dragbounds instead
          }}
        />
      </Group>
    );
  }
);

export default GridHeader;
