import React, { useCallback, useEffect, useRef } from "react";
import {
  CellInterface,
  GridRef,
  SelectionArea,
  Cell,
  CellContainer,
  AreaProps,
  MoveCellHandler,
} from "../Grid";
import { selectionFromActiveCell, prepareClipboardData } from "./../helpers";
import { MimeType } from "../types";

import papaparse from "papaparse";

export interface CopyProps extends MoveCellHandler {
  /**
   * Selection bounds
   */
  selections: SelectionArea[];
  /**
   * Active cell
   */
  activeCell?: CellInterface | null;
  /**
   * Value getter of a cell
   */
  getCell: (rowIndex: number, columnIndex: number) => Cell | undefined;
  /**
   * Get text value
   */
  getText: (config: any) => string | undefined;
  /**
   * Grid reference to access grid methods
   */
  gridRef: React.MutableRefObject<GridRef | null>;

  createCellFromString: (strValue: string) => Cell;

  /**
   * Callback when a paste is executed
   */
  onPaste?: (
    cells: CellContainer[],
    activeCell: CellInterface | null,
    currentSelection: SelectionArea[],
    /* Selection to remove */
    cutSelection?: SelectionArea,
    newSelectionBounds?: AreaProps | null
  ) => void;

  /**
   * Callback when a paste is executed
   */
  onMoveCell?: (from: CellInterface, to: CellInterface, cell?: Cell) => Cell;

  /**
   * When user tries to cut a selection
   */
  onCut?: (selection: SelectionArea) => void;
  /**
   * Callback on copy event
   */
  onCopy?: (selection: SelectionArea[]) => void;
}

export interface CopyResults {
  copy: () => void;
  paste: () => void;
  cut: () => void;
}

type SupportedType =
  | "application/xhtml+xml"
  | "application/xml"
  | "image/svg+xml"
  | "text/html"
  | "text/xml";

const defaultGetText = (text: any) => text;

/**
 * Copy paste hook
 * Usage
 *
 * useCopyPaste ({
 *  onPaste: (text) => {
 *  }
 * })
 */
const useCopyPaste = ({
  selections = [],
  activeCell = null,
  getCell,
  gridRef,
  createCellFromString,
  onPaste,
  onMoveCell,
  onCut,
  onCopy,
  getText = defaultGetText,
}: CopyProps): CopyResults => {
  const selectionRef = useRef({ selections, activeCell, getCell });
  const cutSelections = useRef<SelectionArea>();

  /* Keep selections and activeCell upto date */
  useEffect(() => {
    selectionRef.current = { selections, activeCell, getCell };
  }, [selections, activeCell, getCell]);

  const currentSelections = () => {
    const sel = selectionRef.current.selections.length
      ? selectionRef.current.selections
      : selectionFromActiveCell(selectionRef.current.activeCell);
    return sel[sel.length - 1];
  };

  useEffect(() => {
    if (!gridRef.current) return;
    document.addEventListener("copy", handleCopy);
    document.addEventListener("paste", handlePaste);
    document.addEventListener("cut", handleCut);

    return () => {
      document.removeEventListener("copy", handleCopy);
      document.removeEventListener("paste", handlePaste);
      document.removeEventListener("cut", handleCut);
    };
  }, []);

  const handleCut = useCallback(() => {
    if (document.activeElement !== gridRef.current?.container) {
      return;
    }
    cutSelections.current = currentSelections();
    handleProgramaticCopy();
  }, []);

  const handleCopy = useCallback(
    (e: ClipboardEvent) => {
      if (document.activeElement !== gridRef.current?.container) {
        return;
      }
      /* Only copy the last selection */
      const selection = currentSelections();
      const { bounds } = selection;
      const { top, left, right, bottom } = bounds;
      const rows = [];
      const cells = [];
      for (let i = top; i <= bottom; i++) {
        const row = [];
        const cell = [];
        for (let j = left; j <= right; j++) {
          const coords = {
            rowIndex: i,
            columnIndex: j,
          };
          const currentCell = selectionRef.current.getCell(
            coords.rowIndex,
            coords.columnIndex
          );
          let valueAsText = undefined;
          if (currentCell) {
            valueAsText = getText(currentCell);
            cells.push({
              cell: currentCell,
              ...coords,
            });
          }
          row.push(valueAsText);
        }
        rows.push(row);
      }
      const [html, csv, plain] = prepareClipboardData(rows);
      e.clipboardData?.setData(MimeType.html, html);
      e.clipboardData?.setData(MimeType.plain, plain);
      e.clipboardData?.setData(MimeType.csv, csv);
      e.clipboardData?.setData(MimeType.json, JSON.stringify(cells));
      e.preventDefault();

      onCopy?.([selection]);
    },
    [currentSelections]
  );

  const handlePaste = (e: ClipboardEvent) => {
    if (document.activeElement !== gridRef.current?.container) {
      return;
    }
    const items = e.clipboardData?.items;
    if (!items) return;
    /**
     * - Note - HTML Supports colSpan and rowSpan but our logic doesn't.
     *          Since our primary usecase is to reading from excel/sheets we just disabled
     */
    const mimeTypes = [
      MimeType.json,
      //MimeType.html,
      MimeType.csv,
      MimeType.plain,
    ];
    let type;
    let value;
    let plainTextValue = e.clipboardData?.getData(MimeType.plain);
    for (type of mimeTypes) {
      value = e.clipboardData?.getData(type);
      if (value) break;
    }
    if (!type || !value) {
      console.warn("No clipboard data to paste");
      return;
    }
    let cells: CellContainer[] = [];
    let currentTopLeft = selectionRef.current.activeCell;
    if (!currentTopLeft) currentTopLeft = { rowIndex: 0, columnIndex: 0 };

    let pasteSelection: AreaProps | null = {
      top: currentTopLeft.rowIndex,
      bottom: currentTopLeft.rowIndex,
      left: currentTopLeft.columnIndex,
      right: currentTopLeft.columnIndex,
    };

    if (/^text\/html/.test(type)) {
      const domparser = new DOMParser();
      const doc = domparser.parseFromString(value, type as SupportedType);
      const supportedNodes = "table, p, h1, h2, h3, h4, h5, h6";
      let nodes = doc.querySelectorAll(supportedNodes);
      let height = 1;
      let width = 1;
      if (nodes.length === 0) {
        let cell = createCellFromString(plainTextValue || "");
        cells.push({
          cell,
          ...currentTopLeft,
        });
      } else {
        for (let i = 0; i < nodes.length; i++) {
          const node = nodes[i];
          if (node.nodeName === "TABLE") {
            const tableRows = node.querySelectorAll("tr");
            height = tableRows.length;
            for (let i = 0; i < tableRows.length; i++) {
              const tableRow = tableRows[i];
              const cellsHtml = tableRow.querySelectorAll("td");
              width = Math.max(width, cellsHtml.length);
              for (let j = 0; j < cellsHtml.length; j++) {
                const cellHtml = cellsHtml[j];
                let strValue = "";
                if (cellHtml.textContent !== null)
                  strValue = cellHtml.textContent;

                strValue = strValue.replace(/^\"|\"$/gi, "");
                let cell = createCellFromString(strValue);
                cells.push({
                  cell,
                  rowIndex: currentTopLeft.rowIndex + i,
                  columnIndex: currentTopLeft.columnIndex + j,
                });
              }
            }
          } else if (node.textContent) {
            // Single nodes
            //rows.push([node.textContent]);
            let strValue = node.textContent.replace(/^\"|\"$/gi, "");
            let cell = createCellFromString(strValue);
            cells.push({
              cell,
              rowIndex: currentTopLeft.rowIndex,
              columnIndex: currentTopLeft.columnIndex,
            });
          }
        }
      }

      pasteSelection.top = currentTopLeft.rowIndex;
      pasteSelection.bottom = currentTopLeft.rowIndex + height - 1;
      pasteSelection.left = currentTopLeft.columnIndex;
      pasteSelection.right = currentTopLeft.columnIndex + width - 1;
    } else if (type === MimeType.json) {
      // original anchor
      const originalAnchor: CellInterface = {
        rowIndex: gridRef.current?.rowCount - 1,
        columnIndex: gridRef.current?.columnCount - 1,
      };
      cells = JSON.parse(value);
      for (let i = 0; i < cells.length; i++) {
        originalAnchor.rowIndex = Math.min(
          originalAnchor.rowIndex,
          cells[i].rowIndex
        );
        originalAnchor.columnIndex = Math.min(
          originalAnchor.columnIndex,
          cells[i].columnIndex
        );
      }

      for (let i = 0; i < cells.length; i++) {
        let fromCellRef: CellInterface = {
          rowIndex: cells[i].rowIndex,
          columnIndex: cells[i].columnIndex,
        };
        let toCellRef: CellInterface = {
          rowIndex:
            cells[i].rowIndex -
            originalAnchor.rowIndex +
            currentTopLeft.rowIndex,
          columnIndex:
            cells[i].columnIndex -
            originalAnchor.columnIndex +
            currentTopLeft.columnIndex,
        };
        if (onMoveCell && cells[i].cell) {
          cells[i].cell = onMoveCell(fromCellRef, toCellRef, cells[i].cell);
        }
        cells[i].rowIndex = toCellRef.rowIndex;
        cells[i].columnIndex = toCellRef.columnIndex;

        pasteSelection.top = Math.min(pasteSelection.top, toCellRef.rowIndex);
        pasteSelection.bottom = Math.max(
          pasteSelection.bottom,
          toCellRef.rowIndex
        );
        pasteSelection.left = Math.min(
          pasteSelection.left,
          toCellRef.columnIndex
        );
        pasteSelection.right = Math.max(
          pasteSelection.right,
          toCellRef.columnIndex
        );
      }
    } else if (type === MimeType.plain || type === MimeType.csv) {
      let values = papaparse.parse(value, {
        delimiter: type === MimeType.csv ? "," : "\t",
      }).data;
      if (
        values.length > 1 &&
        (values[values.length - 1].length === 0 ||
          (values[values.length - 1].length === 1 &&
            values[values.length - 1][0].length === 0))
      )
        // dangling newline
        values.pop();
      let maxWidth = 1;
      for (let i = 0; i < values.length; i++) {
        let rowValues = values[i]; //.split(type === MimeType.csv ? "," : "\t");
        maxWidth = Math.max(maxWidth, rowValues.length);
        for (let j = 0; j < rowValues.length; j++) {
          let strValue = rowValues[j]; //.replace(/^\"|\"$/gi, "");
          let cell = createCellFromString(strValue);
          cells.push({
            cell,
            rowIndex: currentTopLeft.rowIndex + i,
            columnIndex: currentTopLeft.columnIndex + j,
          });
        }
      }
      pasteSelection.top = currentTopLeft.rowIndex;
      pasteSelection.bottom = currentTopLeft.rowIndex + values.length - 1;
      pasteSelection.left = currentTopLeft.columnIndex;
      pasteSelection.right = currentTopLeft.columnIndex + maxWidth - 1;
    } else {
      // ?
    }

    //   try {
    //     const clipboardItems = navigator.clipboard.read();
    //     clipboardItems.then(function(clipboardItems) {
    //         console.log(clipboardItems);
    //     });
    //   } catch (err) {
    //     console.error(err.name, err.message);
    //   }

    if (
      pasteSelection.top === pasteSelection.bottom &&
      pasteSelection.left === pasteSelection.right
    )
      pasteSelection = null;

    onPaste &&
      onPaste(
        cells,
        selectionRef.current.activeCell,
        selectionRef.current.selections,
        cutSelections.current,
        pasteSelection
      );

    cutSelections.current = undefined;
  };

  /**
   * User is trying to copy from outisde the app
   */
  const handleProgramaticCopy = useCallback(() => {
    if (!gridRef.current) return;
    gridRef.current.focus();
    document.execCommand("copy");
  }, []);

  /**
   * User is trying to paste from outisde the app
   */
  const handleProgramaticPaste = useCallback(async () => {
    if (!gridRef.current) return;
    gridRef.current.focus();
    const text = await navigator.clipboard.readText();
    const clipboardData = new DataTransfer();
    clipboardData.setData(MimeType.plain, text);
    const event = new ClipboardEvent("paste", { clipboardData });
    handlePaste(event);
  }, []);

  return {
    copy: handleProgramaticCopy,
    paste: handleProgramaticPaste,
    cut: handleCut,
  };
};

export default useCopyPaste;
