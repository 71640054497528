import React, { memo } from "react";
import { SelectionProps } from "./Grid";
import { createHTMLBox } from "./utils";

const Selection: React.FC<SelectionProps> = memo((props) => {
  const { inProgress = false, ...rest } = props;
  return createHTMLBox({ ...rest });
});

export default Selection;
