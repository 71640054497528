import React, { memo } from "react";

import { StageConfig } from "konva/types/Stage";

import { GridRef, SelectionProps } from "./Grid";
import { createHTMLBox } from "./utils";

/**
 *
 * This is similiar to selection except it snaps to cell bounds and support
 * Resize handles
 */

export interface CellSelectionProps
  extends SelectionProps,
    Omit<StageConfig, "container"> {
  gridRef: React.MutableRefObject<GridRef | null>;
}

const CellSelection: React.FC<CellSelectionProps> = memo((props) => {
  const { gridRef, bounds, strokeWidth = 2, ...rest } = props;

  if (gridRef.current && bounds) {
    const boundsActual = gridRef.current.getActualSelectionCoords(
      bounds.left,
      bounds.top,
      bounds.right,
      bounds.bottom
    );
    return createHTMLBox({
      ...rest,
      strokeWidth: strokeWidth,
      x: boundsActual.x - strokeWidth / 2,
      y: boundsActual.y - strokeWidth / 2,
      width: boundsActual.width + strokeWidth / 2,
      height: boundsActual.height + strokeWidth / 2,
    } as SelectionProps);
  } else {
    return <></>;
  }
});

export default CellSelection;
